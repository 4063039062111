<template>
  <div class="oscillator">
    <div class="power-div">
      <filter-toggle v-model="turnedOn" large >Power</filter-toggle>
    </div>
    <div class="oscillator-panel" :class="{ on: turnedOn }">
      <div class="volume-control">
        <div>Volume</div>
        <input type="range" id="volume" class="control-volume" min="0" max="1" v-model="volume" step="0.01" data-action="volume" />
      </div>
      <div class="volume-control">
        <div>Tone Frequency</div>
        <input type="range" class="control-volume" min="400" max="1200" v-model="frequency" step="1" data-action="frequency" />
      </div>
      <div>
        <lamp-view :lit="down"></lamp-view>
      </div>
    </div>
  </div>
</template>

<script>
import LampView from './LampView.vue'
import FilterToggle from './FilterToggle.vue'
import { waitfor } from 'lantix-utils'

const ZERO = 0.001

export default {
  components: {
    LampView,
    FilterToggle
  },
  props: {
    down: Boolean,
    remember: String
  },
  data () {
    return {
      oscillator: null,
      gainNode: null,
      volume: 0.1,
      inited: false,
      frequency: 800,
      turnedOn: false,
      lastUp: 0
    }
  },
  mounted () {
    if (this.remember) {
      console.log('doing restore')
      this.handleRestore()
    }
  },
  methods: {
    init () {
      this.inited = true
      this.context = window.acontext
      this.oscillator = this.context.createOscillator()
      this.oscillator.type = 'sine'

      this.gainNode = this.context.createGain()
      this.gainNode.connect(this.context.destination)
      this.gainNode.gain.value = ZERO
      this.oscillator.frequency.setValueAtTime(this.frequency, this.context.currentTime)
      this.oscillator.connect(this.gainNode)
      this.oscillator.start()
      this.$emit('started')
    },
    handleTurnOn () {
      if (this.turnOn) {
        if (!this.inited) {
          this.init()
        }
      } else {
        if (this.inited) {
          this.stopOscillator()
        }
      }
    },
    async startOscillator () {
      this.gainNode.gain.exponentialRampToValueAtTime(this.volume, this.context.currentTime + 0.02)
      const downtime = new Date().getTime()
      await waitfor(5000)
      if (this.lastUp > downtime) {
        return
      }
      this.stopOscillator()
      this.$emit('reset')
    },
    stopOscillator () {
      try {
        this.lastUp = new Date().getTime()
        this.gainNode.gain.exponentialRampToValueAtTime(ZERO, this.context.currentTime + 0.02)
      } catch (err) {

      }
    },
    handleDown () {
      if (!this.inited) {
        this.init()
      }
      if (this.down && this.turnedOn) {
        this.startOscillator()
      } else {
        this.stopOscillator()
      }
    },
    handleVolume () {
      this.handleSave()
      if (!this.gainNode) {
        return
      }
      this.gainNode.gain.value = this.volume
    },
    handleSave () {
      if (this.remember) {
        const settings = JSON.stringify({
          volume: this.volume,
          frequency: this.frequency
        })
        localStorage.setItem(this.remember, settings)
      }
    },
    handleRestore () {
      if (this.remember) {
        const obj = localStorage.getItem(this.remember)
        if (obj) {
          try {
            const settings = JSON.parse(obj)
            if (settings.volume) {
              this.volume = settings.volume
            }
            if (settings.frequency) {
              this.frequency = settings.frequency
            }
          } catch (err) {
            console.log(err)
          }
        }
      }
    },
    handleFrequency () {
      this.handleSave()
      if (!this.oscillator) {
        return
      }
      this.oscillator.frequency.setValueAtTime(this.frequency, this.context.currentTime)
    },
    forceStart () {
      console.log('forced on')
      this.turnedOn = true
    }
  },
  watch: {
    down () {
      this.handleDown()
    },
    volume () {
      this.handleVolume()
    },
    frequency () {
      this.handleFrequency()
    },
    turnedOn () {
      this.handleTurnOn()
    }
  }
}
</script>

<style>
.volume-control{
  margin-bottom:30px;
}

.oscillator{
  margin-bottom:30px;
}

.oscillator-panel{
  transition:opacity 0.5s linear;
  opacity:0.2;
}

.oscillator-panel.on{
  opacity:1;
}

.power-div{
  margin-bottom:10px;
}
</style>
