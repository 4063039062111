<template>
  <div class="instructions">
    <div class="title" @click="toggleVisible">{{ title }}</div>
    <div class="body" :class="bodyclass"><slot></slot></div>
  </div>
</template>

<script>
import { waitfor } from 'lantix-utils'
export default {
  props: {
    title: String
  },
  data () {
    return {
      visible: false,
      bodyclass: 'hidden'
    }
  },
  methods: {
    toggleVisible () {
      if (!this.visible) {
        this.openBox()
      } else {
        this.closeBox()
      }
    },
    closeIfOpen () {
      if (this.visible) {
        this.closeBox()
      }
    },
    async openBox () {
      this.bodyclass = 'opening'
      await waitfor(10)
      this.visible = true
      this.bodyclass = ''
      await waitfor(200)
      document.addEventListener('click', this.closeBox)
    },
    async closeBox () {
      document.removeEventListener('click', this.closeBox)
      this.visible = false
      this.bodyclass = 'opening'
      await waitfor(200)
      this.bodyclass = 'hidden'
    }
  }
}
</script>

<style>
.instructions {
  position: relative;
  padding:10px;
}
.instructions .title{
  display:inline-block;
  border:1px solid #000;
  background-color:#999;
  padding:4px 10px;
  cursor:pointer;
  border-radius:8px;
}
.instructions .title:hover{
  background-color:#000;
  color:#fff;
}
.instructions .body{
  position: absolute;
  top:0;
  left:50%;
  transform: translate(-50%,0);
  z-index:50;
  box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
  transition:all 0.2s linear;
  background-color:#222;
  padding:0 20px;
  border-radius:10px;
}

.instructions .body.hidden{
  display:none;
}

.instructions .body.opening {
  transform: translate(-50%,-50%) scale(0.2);
}

</style>
