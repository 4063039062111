<template>
  <div class="lamp-holder" :class="{sending: lit}">
    <div class="lamp" ></div>
  </div>
</template>

<script>
export default {
  props: {
    lit: Boolean
  }
}
</script>

<style>
.lamp-holder{
  display:inline-block;
  border:6px solid #444;
  border-radius:50%;
  background-color:#000;
}
.lamp-holder.sending{
  box-shadow:0 0 9px rgba(255, 0, 0);
}
.lamp{
  display:block;
  width:30px;
  height:30px;
  border-radius:50%;
  background-color:red;
  opacity:0.1;
  background-image:url('../assets/redlight.svg');
  background-size:contain;
  background-position:center center;
  background-repeat: no-repeat;

}

.sending .lamp{
  opacity:1;
  box-shadow:0 0 9px rgba(255, 0, 0);
}
</style>
