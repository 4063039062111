function findNext (letter, str) {
  const wh = str.indexOf(letter)
  if (wh < 0) {
    return 0
  }
  if (wh < 3) {
    return wh
  }
  return 0
}

function mycheckResult (s1, s2) {
  let userpos = 0
  let score = 0
  s1 = s1.toLowerCase()
  s2 = s2.toLowerCase()
  for (let i = 0; i < s1.length; i++) {
    const letter = s1[i]
    if (s1[i] === s2[userpos]) {
      score++
      userpos++
    } else {
      userpos += findNext(letter, s2.substring(userpos))
    }
  }
  return (score / s1.length) * 100
}

export {
  mycheckResult
}
