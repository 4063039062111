<template>
  <nav class="topnav">
    <router-link to="/">world morse radio</router-link>
    <router-link to="/koch">koch trainer</router-link>
  </nav>
</template>

<script>

export default {

}
</script>

<style>
.topnav {
  display:flex;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background-color:#444;
  justify-content:center;
  align-items:center;
}
.topnav a{
  display:block;
  color:#ddd;
  background-color:#444;
  padding:3px 12px;
  text-decoration: none;
  transition: all 0.1s linear;
}

.topnav a:hover{
  background-color:#000;
}

a.router-link-exact-active{
  background-color:#333;
}
</style>
