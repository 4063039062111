<template>
  <div>
    <router-view />
  </div>

</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color:#fff;
  margin-top:50px;
}

h1 {
  font-weight:300;
  font-size:36px;
}

h2 {
  font-weight:500;
}

body{
  background-color:#555;
  font-weight:300;
}
</style>
