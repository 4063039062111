import { encode, decode } from 'morse-converter'
import { waitfor } from 'lantix-utils'

/**
 * Options are
 * down - down callback function
 * up - up callback function
 * wpm - words per minute
 * charspace - extra space between characters as %
 * wordspace - extra space between words as %
 */

class MorseGen {
  constructor (options) {
    this.down = () => {
      console.log('down')
    }
    this.up = () => {
      console.log('up')
    }
    this.update = (val) => {
      console.log('update', val)
    }
    this.wpm = 20
    this.charExtra = 0
    this.wordExtra = 0
    this.configure(options)
    this.break = false
    this.sent = ''
  }

  configure (options) {
    this.down = options.down || this.down
    this.up = options.up || this.up
    if (options.wpm) {
      this.wpm = options.wpm || this.wpm
    }
    if (options.update) {
      this.update = options.update
    }
    // const wps = this.wpm / 60
    this.dotlen = Math.round((1.2 / this.wpm) * 1000)
    console.log('dotlen', this.dotlen, 'wpm', (1.2 / this.wpm))
    if (options.wordspace) {
      this.wordExtra = Math.round((this.dotlen * 7) * (options.wordspace / 100))
      console.log('wordextra', this.wordExtra)
    }
    if (options.charspace) {
      this.charExtra = Math.round((this.dotlen * 3) * (options.charspace / 100))
      console.log('charextra', this.charExtra)
    }
  }

  async speak (phrase) {
    this.break = false
    this.sent = ''
    const mphrase = encode(phrase)
    for (let i = 0; i < mphrase.length; i++) {
      if (this.break) {
        break
      }
      await this.speakCode(mphrase[i])
      this.sent += mphrase[i]
      if (mphrase[i] === '/') {
        this.update(decode(this.sent))
      }
    }
    this.break = false
  }

  stop () {
    this.break = true
    return decode(this.sent)
  }

  async speakCode (morsebit) {
    if (morsebit === ' ') {
      await waitfor(this.charExtra + (this.dotlen * 3))
    } else if (morsebit === '.') {
      this.down()
      await waitfor(this.dotlen)
      this.up()
    } else if (morsebit === '-') {
      this.down()
      await waitfor(this.dotlen * 3)
      this.up()
    } else if (morsebit === '/') {
      if (this.wordExtra) {
        await waitfor(this.wordExtra)
      }
    }
    // item space is 1 dot
    await waitfor(this.dotlen)
  }
}

function checkResult (s1, s2) {
  var stack = []
  stack.push(s1)
  stack.push(s2)

  var score = 0

  while (stack.length !== 0) {
    var string1 = stack.pop()
    var string2 = stack.pop()

    var longestSequenceLength = 0
    var longestSequenceIndex1 = -1
    var longestSequenceIndex2 = -1
    for (var i = 0; i < string1.length; i++) {
      for (var j = 0; j < string2.length; j++) {
        var k = 0
        while (i + k < string1.length && j + k < string2.length && string1.charAt(i + k) === string2.charAt(j + k)) {
          k++
        }
        if (k > longestSequenceLength) {
          longestSequenceLength = k
          longestSequenceIndex1 = i
          longestSequenceIndex2 = j
        }
      }
    }

    if (longestSequenceLength > 0) {
      score += longestSequenceLength * 2
      if (longestSequenceIndex1 !== 0 && longestSequenceIndex2 !== 0) {
        stack.push(string1.substring(0, longestSequenceIndex1))
        stack.push(string2.substring(0, longestSequenceIndex2))
      }
      if (longestSequenceIndex1 + longestSequenceLength !== string1.length &&
                longestSequenceIndex2 + longestSequenceLength !== string2.length) {
        stack.push(string1.substring(longestSequenceIndex1 + longestSequenceLength, string1.length))
        stack.push(string2.substring(longestSequenceIndex2 + longestSequenceLength, string2.length))
      }
    }
  }
  return score / (s1.length + s2.length)
}

function getRandomInt (max) {
  return Math.floor(Math.random() * max)
}

export {
  MorseGen, getRandomInt, checkResult
}
