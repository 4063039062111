<template>
  <div class="hello">
    <h1>Cookie Run Kingdom Skill Powder Calculator</h1>
    <div class="result-area col">
      <p>From Skill Level</p>
      <div>
        <div>
          <input type="range" v-model="fromLevel" :min="1" :max="60" step="1">
        </div>
        <div class="result amt" :class="{refined: fromLevel > 19 && fromLevel < 40 , pristine: fromLevel >= 40}">{{ fromLevel }}</div>
      </div>
      <p>To Skill Level</p>
      <div>
        <div>
          <input type="range" v-model="toLevel" :min="1" :max="60" step="1">

        </div>
        <div class="result amt" :class="{refined: toLevel > 20 && toLevel < 41 , pristine: toLevel > 40}">{{ toLevel }}</div>
      </div>
    </div>
    <p>You Will Need</p>
    <div class="result-area">
      <div class="result powder" v-show="powder" title="Skill Powder">{{ powder }}</div>
      <div class="result refined" v-show="refinedPowder" title="Refined Skill Powder">{{ refinedPowder }}</div>
      <div class="result pristine" v-show="pristinePowder" title="Pristine Skill Powder">{{ pristinePowder }}</div>
      <div class="result coin" v-show="coins" title="Coins">{{ coins}} </div>
    </div>

  </div>
</template>

<script>
const regular = [
  { level: 1, coins: 0, powders: 0 },
  { level: 2, coins: 100, powders: 1 },
  { level: 3, coins: 200, powders: 1 },
  { level: 4, coins: 300, powders: 2 },
  { level: 5, coins: 400, powders: 2 },
  { level: 6, coins: 500, powders: 3 },
  { level: 7, coins: 600, powders: 3 },
  { level: 8, coins: 700, powders: 4 },
  { level: 9, coins: 800, powders: 4 },
  { level: 10, coins: 900, powders: 5 },
  { level: 11, coins: 1000, powders: 5 },
  { level: 12, coins: 1100, powders: 6 },
  { level: 13, coins: 1200, powders: 6 },
  { level: 14, coins: 1300, powders: 7 },
  { level: 15, coins: 1400, powders: 7 },
  { level: 16, coins: 1500, powders: 8 },
  { level: 17, coins: 1700, powders: 8 },
  { level: 18, coins: 1800, powders: 9 },
  { level: 19, coins: 1900, powders: 9 },
  { level: 20, coins: 900, powders: 10 }
]

const refined = [
  { level: 21, coins: 2000, powders: 1 },
  { level: 22, coins: 2600, powders: 1 },
  { level: 23, coins: 3200, powders: 2 },
  { level: 24, coins: 3800, powders: 2 },
  { level: 25, coins: 4400, powders: 3 },
  { level: 26, coins: 5000, powders: 3 },
  { level: 27, coins: 5600, powders: 4 },
  { level: 28, coins: 6200, powders: 4 },
  { level: 29, coins: 6800, powders: 5 },
  { level: 30, coins: 7400, powders: 5 },
  { level: 31, coins: 8000, powders: 6 },
  { level: 32, coins: 8600, powders: 6 },
  { level: 33, coins: 9200, powders: 7 },
  { level: 34, coins: 9800, powders: 7 },
  { level: 35, coins: 10400, powders: 8 },
  { level: 36, coins: 11000, powders: 8 },
  { level: 37, coins: 11600, powders: 9 },
  { level: 38, coins: 12200, powders: 9 },
  { level: 39, coins: 12800, powders: 10 },
  { level: 40, coins: 13400, powders: 10 }
]

const pristine = [
  { level: 41, coins: 14000, powders: 2 },
  { level: 42, coins: 15600, powders: 4 },
  { level: 43, coins: 17200, powders: 6 },
  { level: 44, coins: 18800, powders: 8 },
  { level: 45, coins: 20400, powders: 10 },
  { level: 46, coins: 22000, powders: 12 },
  { level: 47, coins: 23600, powders: 14 },
  { level: 48, coins: 25200, powders: 16 },
  { level: 49, coins: 26800, powders: 18 },
  { level: 50, coins: 28400, powders: 20 },
  { level: 51, coins: 32000, powders: 20 },
  { level: 52, coins: 34000, powders: 22 },
  { level: 53, coins: 36000, powders: 22 },
  { level: 54, coins: 38000, powders: 24 },
  { level: 55, coins: 40000, powders: 24 },
  { level: 56, coins: 42000, powders: 26 },
  { level: 57, coins: 44000, powders: 26 },
  { level: 58, coins: 46000, powders: 28 },
  { level: 59, coins: 48000, powders: 28 },
  { level: 60, coins: 50000, powders: 30 }
]

export default {
  computed: {
    powder () {
      let res = 0
      console.log('fromLevel', this.fromLevel, 'toLevel', this.toLevel)
      const max = Math.min(this.toLevel, 20)
      for (let i = this.fromLevel; i < max; i++) {
        res += regular[i].powders
      }
      return res
    },
    refinedPowder () {
      let res = 0
      let start = this.fromLevel - 20
      if (start < 0) {
        start = 0
      }
      const max = Math.min(this.toLevel - 20, 20)
      for (let i = start; i < max; i++) {
        res += refined[i].powders
      }
      return res
    },
    pristinePowder () {
      let res = 0
      let start = this.fromLevel - 40
      if (start < 0) {
        start = 0
      }
      const max = Math.min(this.toLevel - 40, 20)
      for (let i = start; i < max; i++) {
        res += pristine[i].powders
      }
      return res
    },
    coins () {
      let res = 0
      let max = Math.min(this.toLevel, 20)
      for (let i = this.fromLevelCalc; i < max; i++) {
        res += regular[i].coins
      }
      let start = this.fromLevelCalc - 20
      if (start < 0) {
        start = 0
      }
      max = Math.min(this.toLevel - 20, 20)
      for (let i = start; i < max; i++) {
        res += refined[i].coins
      }
      start = this.fromLevelCalc - 40
      if (start < 0) {
        start = 0
      }
      max = Math.min(this.toLevel - 40, 20)
      for (let i = start; i < max; i++) {
        res += pristine[i].coins
      }
      return res
    },
    fromLevelCalc () {
      return this.fromLevel - 1
    }
  },
  data () {
    return {
      fromLevel: 1,
      toLevel: 60
    }
  },
  created () {
    document.title = 'Cookie Run Kingdom Skill Powder Calculator'
  }
}
</script>

<style>
.result{
  background-color:rgb(61, 129, 129);
  -webkit-text-stroke:1px black;
  color:#fff;
  margin:10px;
  padding:10px;
  font-size:24px;
  font-weight:900;
  font-family:'Arial Black', 'Arial Bold', Gadget, sans-serif;
  border-radius:10px;
  background-position: left 5px center;
  background-size:37px 37px;
  background-repeat: no-repeat;
  padding-left: 40px;
  border:1px solid #fff;
  box-shadow:3px 3px 7px #000;
}

.result.amt{
  display:inline-block;
  background-color:rgb(216, 193, 88);
  background-image:url('../assets/Common_powder.webp');
}

input[type="range"]{
  width:320px;
}

.result.powder{
  background-image:url('../assets/Common_powder.webp');
}

.result.pristine{
  background-image:url('../assets/Pristine_powder.webp')
}

.result.refined{
  background-image:url('../assets/Refined_powder.webp')
}

.result.coin{
  background-image:url('../assets/Coin.webp')
}

.result-area{
  display:flex;
  justify-content: center;
  align-items:center;
  margin:20px;
}

.result-area.col{
  flex-direction: column;
}
</style>
