<template>
  <div class="hello">
    <navigator-box />
    <h1>Koch Trainer</h1>
    <div class="key-container koch">
      <div class="side-by-side">
        <oscillator-tone :down="receiving" ref="osc" remember="kochosc" />
        <div>
          <div class="volume-control">
            <div>Characters: {{ numchars }}</div>
            <input type="range" class="control-volume" min="2" :max="this.kochOrder.length" v-model="numchars" step="1" />
          </div>
          <div class="volume-control">
            <div>Words per minute: {{ wpm }}</div>
            <input type="range" class="control-volume" min="5" max="50" v-model="wpm" step="1" />
          </div>
          <div class="volume-control">
            <div>Extra character space: {{ charextra }}%</div>
            <input type="range" class="control-volume" min="0" max="200" v-model="charextra" step="5" />
          </div>
          <div class="volume-control">
            <div>Extra word space: {{ wordextra }}%</div>
            <input type="range" class="control-volume" min="0" max="300" v-model="wordextra" step="5" />
          </div>
        </div>
      </div>
      <div class="controls">
        <button @click="handleStart">Start</button>
        <button @click="handleStop">Stop</button>
      </div>
      <div class="decoder">
        <textarea v-model="notepad" cols="60" rows="4" spellcheck="false" ref="notepad"></textarea>
      </div>
      <div class="score">
        Score {{ score }}% My Score {{ myscore }}
      </div>
    </div>
  </div>
</template>

<script>
import NavigatorBox from '../components/NavigatorBox.vue'
import OscillatorTone from '../components/OscillatorTone.vue'
import { getRandomInt, MorseGen, checkResult } from '../components/morse-utils.js'
import { mycheckResult } from '../components/mycheckresult.js'
import { waitfor } from 'lantix-utils'

export default {
  components: {
    NavigatorBox,
    OscillatorTone
  },
  data () {
    return {
      wpm: 20,
      charextra: 0,
      wordextra: 0,
      numchars: 2,
      notepad: '',
      kochstring: '',
      receiving: false,
      mgen: null,
      kochOrder: 'KMRSUAPTLOWINJEF0YVGQZHBCDX',
      score: 0,
      myscore: 0
    }
  },
  mounted () {
    this.loadKochPrefs()
    this.mgen = new MorseGen({
      down: () => {
        this.receiving = true
      },
      up: () => {
        this.receiving = false
      },
      wpm: this.wpm,
      update: this.updateScore
    })
    document.title = 'KO4SPY Koch Trainer'
  },
  methods: {
    saveKochPrefs () {
      const kp = JSON.stringify({
        wpm: this.wpm,
        charextra: this.charextra,
        wordextra: this.wordextra,
        numchars: this.numchars
      })
      window.localStorage.setItem('kochpref', kp)
    },
    loadKochPrefs () {
      const item = localStorage.getItem('kochpref')
      if (item) {
        try {
          const kp = JSON.parse(item)
          this.wpm = kp.wpm
          this.charextra = kp.charextra
          this.wordextra = kp.wordextra
          this.numchars = kp.numchars
        } catch (err) {
          console.log(err)
        }
      } else {
        this.saveKochPrefs()
      }
    },
    generateRandomKoch () {
      const maxlen = this.wpm * 3
      let koch = ''
      for (let i = 0; i < maxlen; i++) {
        koch += this.generateWord()
        koch += ' '
      }
      return koch
    },
    generateWord () {
      const wlen = getRandomInt(8)
      let ret = ''
      for (let i = 0; i < wlen; i++) {
        ret += this.getRandomLetter()
      }
      return ret
    },
    getRandomLetter () {
      const which = getRandomInt(this.numchars)
      return this.kochOrder[which]
    },
    async handleStart () {
      this.notepad = ''
      this.$refs.osc.forceStart()
      this.mgen.stop()
      this.$refs.notepad.focus()
      await waitfor(1000)
      this.kochstring = this.generateRandomKoch()
      this.mgen.speak(this.kochstring)
    },
    handleStop () {
      this.kochstring = this.mgen.stop()
      this.updateScore(this.kochstring)
      console.log('score', this.score)
      console.log(this.kochstring, 'vs', this.notepad)
    },
    updateScore (ks) {
      this.score = Math.round(checkResult(ks.toLowerCase(), this.notepad.toLowerCase()) * 100)
      this.myscore = mycheckResult(this.kochstring.toLowerCase(), this.notepad.toLowerCase())
    }
  },
  watch: {
    wpm () {
      this.mgen.configure({ wpm: this.wpm })
      this.saveKochPrefs()
    },
    charextra () {
      this.mgen.configure({ charspace: this.charextra })
      this.saveKochPrefs()
    },
    wordextra () {
      this.mgen.configure({ wordspace: this.wordextra })
      this.saveKochPrefs()
    },
    numchars () {
      this.saveKochPrefs()
    }
  }

}
</script>

<style>
.decoder textarea {
  border:none;
  background-color:transparent;
  width:100%;
  resize: none;
  height:100%;
  box-sizing: border-box;
  padding:10px;
  color:#0a0;
  font-family:Consolas;
  font-weight:bold;
}

.decoder textarea:focus{
  outline:none;
}

.koch .decoder{
  height:200px;
  max-height:none;
  display:inline-block;
  box-sizing: border-box;
  padding:10px;
  overflow:hidden;
}

.koch .controls{
  margin-top:16px;
}

.side-by-side{
  display:flex;
  justify-content:center;
}

.side-by-side > div{
  margin:0 20px;
}

.koch button{
  border-radius:4px;
  padding:16px;
  margin:16px;
  cursor:pointer;
  border:1px solid #444;
  background-color:#ccc;
}

.koch button:hover{
  box-shadow:2px 2px 6px rgba(0,0,0,0.5);
  background-color:#eee;
}

.koch{
  max-width:500px;
  display:inline-block;
}

.score{
  margin:10px;
}
</style>
