
import { createRouter, createWebHistory } from 'vue-router'
import WorldRadio from './pages/WorldRadio.vue'
import KochTrainer from './pages/KochTrainer.vue'
import CookieCalc from './pages/CookieCalculator.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: WorldRadio
  },
  {
    path: '/koch',
    name: 'Koch',
    component: KochTrainer
  },
  {
    path: '/cookie',
    name: 'Cookie Calculator',
    component: CookieCalc
  }

]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
