const patternMap = {
  '.-': 'A',
  '-...': 'B',
  '-.-.': 'C',
  '-..': 'D',
  '.': 'E',
  '..-.': 'F',
  '--.': 'G',
  '....': 'H',
  '..': 'I',
  '.---': 'J',
  '-.-': 'K',
  '.-..': 'L',
  '--': 'M',
  '-.': 'N',
  '---': 'O',
  '.--.': 'P',
  '--.-': 'Q',
  '.-.': 'R',
  '...': 'S',
  '-': 'T',
  '..-': 'U',
  '...-': 'V',
  '.--': 'W',
  '-..-': 'X',
  '-.--': 'Y',
  '--..': 'Z',
  '-----': '0',
  '.----': '1',
  '..---': '2',
  '...--': '3',
  '....-': '4',
  '.....': '5',
  '-....': '6',
  '--...': '7',
  '---..': '8',
  '----.': '9',
  '.-.-.-': '.',
  '--..--': ',',
  '..--..': '?'
}

let totDash = 0
let dashTimes = 0

class Morse {
  constructor (callback, morseback) {
    this.emit = (char) => {
      callback(char)
    }

    this.emitRaw = (char) => {
      if (morseback) {
        morseback(char)
      }
    }

    this.updateLengths(150)
    this.sequence = ''
    this.keyDownDate = null
    this.resolveTimer = null
    this.spaceTimer = null
    this.sendTimer = null
  }

  updateLengths (dotlen) {
    if (dotlen < 40) {
      dotlen = 40
    }
    if (dotlen > 200) {
      dotlen = 200
    }
    this.dotDuration = dotlen
    this.maxdot = dotlen + (dotlen / 1.5)
    this.dashDuration = dotlen * 3
    this.wordGap = dotlen * 7
  }

  down () {
    if (this.keyDownDate) return
    clearTimeout(this.resolveTimer)
    clearTimeout(this.spaceTimer)
    this.keyDownDate = new Date().getTime()
  }

  up () {
    const keyPressDuration = (new Date().getTime() - this.keyDownDate)
    this.keyDownDate = null
    if (keyPressDuration <= this.maxdot) {
      this.sequence += '.'
    } else {
      this.sequence += '-'
      const ave = totDash / dashTimes
      if (dashTimes > 5) {
        this.updateLengths(ave / 3)
      }
      totDash += keyPressDuration
      dashTimes++
    }

    // Wait to see if we need to resolve the current sequence
    this.resolveTimer = setTimeout(() => {
      var character = this.resolveSequence()
      this.emit(character)

      this.spaceTimer = setTimeout(() => {
        this.emit(' ')
      }, this.wordGap)
    }, (this.dotDuration * 2))
  }

  clearTimers (reset) {
    this.sequence = ''
    clearTimeout(this.resolveTimer)
    clearTimeout(this.spaceTimer)

    if (reset) {
      this.spaceTimer = setTimeout(() => {
        this.emit(' ')
      }, this.wordGap)
    }
  }

  resolveSequence () {
    const character = patternMap[this.sequence]
    this.sequence = ''
    if (!character) return '?'
    return character
  };
}

export default Morse
