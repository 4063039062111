<template>
  <div class="hello">
    <navigator-box />
    <h1>World Morse Radio</h1>
    <instruction-box title="?">
      <p>Use the button marked "key" or press and release your Control (Command on Mac) key to send.</p>
      <p>Select a channel to broadcast and receive on. If you have a friend who knows morse, get them to broadcast on your receive channel and you can chat.</p>
      <p>Turn on the power on the send and/or receive radios to hear the morse signals.</p>
    </instruction-box>
    <div class="key-holder">
      <div class="key-container">
        <h2>Send</h2>
        <oscillator-tone :down="sending" @reset="sending = false"></oscillator-tone>
        <div class="volume-control">
          <div>Broadcast Channel {{ channel }}</div>
          <input type="range" class="control-channel" min="1" max="100" v-model="channel" step="1" />
        </div>
        <div class="key">
          <div @mousedown.prevent.stop="handleDown" @touchstart.stop="handleDown" @mouseup.prevent.stop="handleUp" @touchend.stop="handleUp">KEY</div>
        </div>
        <div class="decoder">
          <div class="decoded-text">
            {{ entered }}
          </div>
        </div>
      </div>
      <div class="key-container">
        <receiver :socket="socket"></receiver>
      </div>
    </div>
  </div>
</template>

<script>
import { io } from 'socket.io-client'
import Receiver from '../components/Receiver.vue'
import OscillatorTone from '../components/OscillatorTone.vue'
import InstructionBox from '../components/InstructionBox.vue'
import NavigatorBox from '../components/NavigatorBox.vue'
import Morse from '../components/MorseMangler.js'

export default {
  name: 'Morscillator',
  props: {
    msg: String
  },
  components: {
    Receiver,
    OscillatorTone,
    InstructionBox,
    NavigatorBox
  },
  data () {
    return {
      socket: null,
      channel: '10',
      sending: false,
      entered: '',
      morse: null
    }
  },
  mounted () {
    window.addEventListener('keydown', this.handleDownKey)
    window.addEventListener('keyup', this.handleUpKey)
    window.addEventListener('mouseup', this.handleUp)
    this.socket = io('flashy-deeply-dosa.glitch.me', {
      reconnectionDelayMax: 10000,
      query: {
        hello: 'mr-dodgy'
      }
    })
    this.morse = new Morse((char) => {
      this.entered += char
      if (this.entered.length > 30) {
        this.entered = this.entered.substring(this.entered.length - 30)
      }
    })
    document.title = 'KO4SPY World Morse Radio'
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.handleDownKey)
    window.removeEventListener('keyup', this.handleUp)
    window.removeEventListener('mouseup', this.handleUp)
  },
  methods: {
    handleUpKey (evt) {
      if (evt.keyCode === 17) {
        this.handleUp()
      }
    },
    handleDownKey (evt) {
      if (evt.keyCode === 17) {
        this.handleDown()
      }
    },
    handleDown () {
      this.sending = true
      this.morse.down()
      this.socket.emit('down', '' + this.channel)
    },
    handleUp () {
      if (this.sending) {
        this.sending = false
        this.socket.emit('up', '' + this.channel)
        this.morse.up()
      }
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.volume-control{
  margin-bottom:30px;
}

.key-container{
  padding:20px 80px 60px 80px;
  border:1px solid #ddd;
  border-radius:12px;
  display:block;
  margin:10px 30px;
  background-color:#ccc;
  color:#000;
  box-shadow:2px 2px 16px rgba(0,0,0,0.4);
}

.key-holder{
  display:inline-flex;
}

.key-container.disabled{
  opacity:0.4;
}

.key{
  margin:30px 0 0 0;
}

.key > div{
  width:60px;
  height:60px;
  cursor:pointer;
  user-select: none;
  border-radius:50%;
  display:inline-block;
  border:1px solid #666;
  line-height:60px;
}

.decoder{
  margin-top:20px;
  width:220px;
  max-height:40px;
  text-align: right;
  background-color:#000;
  color:greenyellow;
  font-family:'Consolas','Courier New', Courier, monospace;
  font-weight:bold;
  padding:0 6px;
  border:1px solid #fff;
  min-height:18px;
  overflow-x:hidden;
  display:flex;
  justify-content:flex-end;
}

.decoded-text {
  text-align:right;
  white-space: nowrap;
}

</style>
