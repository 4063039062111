<template>
  <div class="receiver">
    <h2>Receive</h2>
    <oscillator-tone :down="receiving" />
    <div class="volume-control">
      <div>Receive Channel {{ channel }}</div>
      <input type="range" class="control-channel" min="1" max="100" v-model="channel" step="1" />
    </div>
    <div class="decoder">
      {{ entered }}
    </div>
  </div>
</template>

<script>
import OscillatorTone from './OscillatorTone.vue'
import Morse from '../components/MorseMangler.js'

export default {
  props: {
    socket: Object
  },
  components: {
    OscillatorTone
  },
  mounted () {
    if (this.socket) {
      this.initSocket()
    }
    this.morse = new Morse((char) => {
      this.entered += char
      if (this.entered.length > 30) {
        this.entered = this.entered.substring(this.entered.length - 30)
      }
    })
  },
  data () {
    return {
      receiving: false,
      channel: '10',
      entered: ''
    }
  },
  methods: {
    initSocket () {
      this.socket.on('down', (data) => {
        const chan = data
        if (chan === this.channel) {
          console.log('got chan', chan, this.channel)
          this.morse.down()
          this.receiving = true
        }
      })
      this.socket.on('up', (data) => {
        const chan = data
        if (chan === this.channel) {
          this.morse.up()
          this.receiving = false
        }
      })
    }
  },
  watch: {
    socket () {
      if (this.socket) {
        this.initSocket()
      }
    }
  }
}
</script>

<style>

</style>
